import { routeToUrl } from "@app-routes";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { BecomeOurUser } from "@ui/Layout/LandingScreen/styles";
import { SmileShape } from "@ui/SmileShape";
import { Text } from "@ui/Text";
import { FC, memo } from "react";
import { useTheme } from "styled-components";
import { IBecomeOurUserSection } from "./types";

const BecomeOurUserSectionComponent: FC<IBecomeOurUserSection> = (props) => {
    const theme = useTheme();
    const { btnGtmId } = props;
    const { t } = useTranslation();
    return (
        <LandingPageContainer>
            <BecomeOurUser>
                <SmileShape id="patronsFooterSmile" solid variant="primary" width={34} />
                <Text
                    as="p"
                    textVariant="h2"
                    mb={theme.legacyGutter(4)}
                    mt={theme.legacySpacing(3)}
                    textFontWeight="medium"
                >
                    {t("component-landing-become-our-user-section:become-our-user")}
                </Text>
                <Button
                    className="button"
                    gtmId={btnGtmId}
                    href={routeToUrl("auth-zone/registration", {})}
                    shadow
                    size="large"
                    variant="secondary"
                    width={210}
                >
                    {t("component-landing-become-our-user-section:register-self")}
                </Button>
            </BecomeOurUser>
        </LandingPageContainer>
    );
};

export const BecomeOurUserSection = memo(BecomeOurUserSectionComponent);
