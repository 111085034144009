import { Box } from "@ui/Box";
import { Container } from "@ui/Container";
import { SmileShape } from "@ui/SmileShape";
import { FC } from "react";
import { Content, ContentTextWrapper, OverlayMockUpMobile, Root, ShapeContainer } from "./styles";
import { IHeroSectionProps } from "./types";

export const HeroSection: FC<IHeroSectionProps> = (props) => {
    const {
        bkgImage,
        bkgImageHeight,
        bkgImagePosition,
        bulletColor,
        center,
        children,
        contentMaxWidth,
        contentShadowTransparency,
        contentShadowWidth, // number 0-1 -> *100% of content width, if not present -> no shadow
        mb,
        mt,
        outerChildren,
        overlayImage,
        shapeLeft,
        shapeTop,
        verticalAlign,
        ...restProps
    } = props;

    return (
        <Container maxWidth={1408} mb={mb} mt={mt}>
            <Box boxPosition="relative">
                <Root
                    bkgImage={bkgImage}
                    bkgImageHeight={bkgImageHeight}
                    bkgImagePosition={bkgImagePosition}
                    center={center}
                    overlayImage={overlayImage}
                    shapeLeft={shapeLeft}
                    shapeTop={shapeTop}
                    verticalAlign={verticalAlign}
                    {...restProps}
                >
                    <Content
                        bulletColor={bulletColor}
                        center={center}
                        contentMaxWidth={contentMaxWidth}
                        contentShadowWidth={contentShadowWidth}
                        contentShadowTransparency={contentShadowTransparency}
                        overlayImage={overlayImage}
                    >
                        <ContentTextWrapper>{children}</ContentTextWrapper>
                    </Content>

                    {overlayImage ? <OverlayMockUpMobile screenShot={overlayImage} /> : null}

                    <ShapeContainer>
                        <SmileShape className="smile" id="heroSectionSmile" variant="inherit" />
                    </ShapeContainer>
                </Root>
                {outerChildren}
            </Box>
        </Container>
    );
};
