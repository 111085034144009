import { mediaMin } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Section = styled("div")<HTMLAttributes<HTMLDivElement>>`
    width: 100%;
    margin: 0 auto;
`;

export const BecomeOurUser = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        margin: ${rem(theme.legacyGutter(3))} auto ${rem(theme.legacyGutter(6))};
        text-align: center;

        ${mediaMin(theme.breakpoint.md)} {
            margin: ${rem(theme.legacyGutter(6))} auto ${rem(theme.legacyGutter(12))};
        }
    `,
);
