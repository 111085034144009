import { useLocationAutocompleteQuery } from "@gql-schema";
import { ErrorService } from "@services/ErrorService";
import { ISelectKeyValues, transformSmartAddressToOptions } from "@utils/formHelper";
import { useCallback } from "react";

export type Hook = (level?: number) => [(term: string) => Promise<void | ISelectKeyValues[]>];

export const useHandleAddress: Hook = (level = 6) => {
    const { refetch: refetchAddress } = useLocationAutocompleteQuery({
        variables: { term: "liberec", level, skip: true },
    });

    const handleAddress = useCallback(
        async (term = "praha"): Promise<void | ISelectKeyValues[]> => {
            try {
                const { data } = await refetchAddress({ term, skip: false });
                return transformSmartAddressToOptions(data?.locationAutocomplete || []);
            } catch (e) {
                ErrorService.handleError(e);
            }
        },
        [refetchAddress],
    );

    return [handleAddress];
};
