import { LandingAddressSelect } from "@components/LandingPage/LandingAddressSelect/LandingAddressSelect";
import { LandingAddressSelectSource } from "@components/LandingPage/LandingAddressSelect/types";
import { useTranslation } from "@translations/use-translation";
import { HeroSection } from "@ui/HeroSection";
import { TextH1 } from "@ui/Text";
import { FC, HTMLAttributes, memo } from "react";

export interface LandingPageFooterProps extends HTMLAttributes<HTMLDivElement> {
    source?: LandingAddressSelectSource;
}

const LandingPageFooterComponent: FC<LandingPageFooterProps> = (props) => {
    const { source } = props;
    const { t } = useTranslation();
    return (
        <HeroSection
            bkgFilter="linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))"
            bkgImage="/static/images/landingPage/hero-footer.jpg"
            bkgImageHeight={437}
            center
        >
            <TextH1 mb={48}>
                {t("component-landing-footer:look-around")}
                <br />
                {t("component-landing-footer:how-is-your-neighbours")}
            </TextH1>
            <LandingAddressSelect buttonProps={{ variant: "secondary" }} label="Kdo je okolo vás?" source={source} />
        </HeroSection>
    );
};

export const LandingPageFooter = memo(LandingPageFooterComponent);
