import { FC } from "react";
import { IMockUpProps } from "./types";

export const MockUpMobile: FC<IMockUpProps> = (props) => {
    const { screenShot, ...restProps } = props;
    return (
        <svg
            height="100%"
            width="100%"
            {...restProps}
            style={{ ...(props?.style ?? {}), overflow: "visible" }}
            viewBox="0 0 250.56 504.36"
        >
            <defs>
                <filter id="dropShadow" x="-50%" y="-50%" width="200%" height="200%">
                    <feOffset result="offOut" in="SourceGraphic" dx="15" dy="15" />
                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="30" />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
            </defs>
            <path
                d="M214.39,0H36.17C16.19,0,0,16.19,0,36.17v432.03c0,19.97,16.19,36.17,36.17,36.17h178.23c19.97,0,36.17-16.19,36.17-36.17V36.17C250.56,16.19,234.37,0,214.39,0z"
                fill="#a0a0a0"
                filter="url(#dropShadow)"
                opacity=".5"
            />
            <image
                height="477.78"
                preserveAspectRatio="xMidYMin slice"
                width="223.98"
                x="13.29"
                xlinkHref={screenShot}
                y="13.29"
            />
            <path
                d="M214.39 0H36.17C16.19 0 0 16.19 0 36.17V468.2c0 19.97 16.19 36.17 36.17 36.17H214.4c19.97 0 36.17-16.19 36.17-36.17V36.17C250.56 16.19 234.37 0 214.39 0zm21.88 466.9c0 12.79-10.37 23.17-23.17 23.17H37.45c-12.79 0-23.17-10.37-23.17-23.17V37.45c0-12.79 10.37-23.17 23.17-23.17h26.11c1.1 0 2 .9 2 2v5.34c0 5.54 4.49 10.04 10.04 10.04h99.36c5.54 0 10.04-4.49 10.04-10.04v-5.34c0-1.1.9-2 2-2h26.11c12.79 0 23.17 10.37 23.17 23.17V466.9z"
                fill="#f2f2f2"
            />
        </svg>
    );
};
