import { MockUpMobile } from "@ui/MockUp";
import { spacing } from "@uxf/styles/units/spacing";
import { mediaMin, withUnit } from "@uxf_base/utils/styling";
import transparentize from "polished/lib/color/transparentize";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { HeroSectionWithDropdownWrapperProps, IContentProps, IRootProps } from "./types";

export const ContentTextWrapper = styled("div")<HTMLAttributes<HTMLDivElement>>`
    position: relative;
`;

export const Content = styled("div")<IContentProps>(
    ({
        bulletColor,
        center,
        contentMaxWidth,
        contentShadowWidth,
        contentShadowTransparency,
        overlayImage,
        theme,
    }) => css`
        position: relative;
        text-align: center;
        font-weight: ${theme.typography.fontWeight.medium};
        max-width: ${contentMaxWidth ? rem(contentMaxWidth) : null};
        width: 100%;
        z-index: 3;

        &::before {
            background-color: ${transparentize(
                contentShadowTransparency ? contentShadowTransparency : 0.5,
                theme.color.palette.black,
            )};
            filter: blur(80px);
            height: 90%;
            left: 15%;
            opacity: ${contentShadowWidth ? 1 : 0};
            position: absolute;
            top: 0;
            transform: translateZ(0);
            width: 70%;

            ${mediaMin(theme.breakpoint.md)} {
                content: "";
                height: 80%;
            }

            ${mediaMin(theme.breakpoint.lg)} {
                filter: blur(50px);
                height: 90%;
                left: -5%;
                top: -5%;
                width: ${contentShadowWidth ? withUnit(contentShadowWidth + 0.1, "%") : null};
            }
        }

        .subCaption {
            margin-left: auto;
            margin-right: auto;
        }

        .underline {
            text-decoration: underline;
            text-decoration-color: ${transparentize(0.5, theme.color.palette.white)};
        }

        ${mediaMin(theme.breakpoint.lg)} {
            margin: ${center ? "0 auto" : "0 auto 0 0"};
            text-align: ${center ? "center" : "left"};
            width: ${!overlayImage ? "80%" : "70%"};
        }

        ${mediaMin(theme.breakpoint.xl)} {
            .subCaption {
                margin-left: 0;
            }
        }

        ul {
            padding: 0;
            text-align: center;

            ${mediaMin(theme.breakpoint.lg)} {
                text-align: left;
            }
        }

        ul li {
            list-style: none;
            padding: 0;
            position: relative;
            text-align: center;

            ${mediaMin(theme.breakpoint.lg)} {
                margin-left: ${rem(spacing(3))};
                text-align: left;
            }
        }

        ul li a {
            display: inline;
        }

        li::before {
            display: none;

            ${mediaMin(theme.breakpoint.lg)} {
                color: ${bulletColor ? theme.color.palette[bulletColor] : theme.color.palette.secondary};
                content: "•";
                display: inline-block;
                font-size: 1.5em;
                left: ${rem(spacing(-4))};
                position: absolute;
                top: 0;
            }
        }
    `,
);

export const DropdownWrapper = styled("div")<HeroSectionWithDropdownWrapperProps>(
    ({ dropdownPosition = "bottomRight", theme }) => css`
        bottom: ${rem(spacing(2))};
        display: flex;
        justify-content: center;
        left: 0;
        padding-left: ${rem(20)};
        padding-right: ${rem(20)};
        position: absolute;
        right: 0;
        z-index: 3;

        ${mediaMin(theme.breakpoint.md)} {
            bottom: ${dropdownPosition === "bottomRight" ? rem(spacing(3)) : "unset"};
            justify-content: flex-end;
            padding-left: ${rem(spacing(4))};
            padding-right: ${rem(spacing(4))};
            top: ${dropdownPosition === "topRight" ? rem(spacing(3)) : undefined};
        }
    `,
);

export const Root = styled("div")<IRootProps>(
    ({
        bkgFilter,
        bkgImage,
        bkgImageHeight,
        bkgImagePosition,
        bkgImagePositionMobile,
        shapeLeft,
        shapeTop,
        shapeVariant,
        textColor,
        theme,
        verticalAlign,
    }) => css`
        align-items: center;
        background:
            linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            ${bkgImage ? "url(" + bkgImage + ")" : null} center / cover;
        background-position: ${bkgImagePositionMobile ? bkgImagePositionMobile : "25% 80%"};
        color: ${textColor ? theme.color.palette[textColor] : theme.color.palette.white};
        display: flex;
        flex-direction: column;
        justify-content: ${!!verticalAlign && verticalAlign === "top"
            ? "flex-start"
            : !!verticalAlign && verticalAlign === "bottom"
              ? "flex-end"
              : "center"};

        min-height: ${bkgImageHeight ? withUnit(bkgImageHeight, "px") : "570px"};
        padding: ${rem(spacing(5))} ${rem(spacing(2.5))};
        position: relative;
        width: 100%;

        ${mediaMin(theme.breakpoint.sm)} {
            padding: ${rem(spacing(6))} ${rem(spacing(4))};
        }

        ${mediaMin(theme.breakpoint.md)} {
            background:
                ${bkgFilter ? bkgFilter : "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))"},
                ${bkgImage ? "url(" + bkgImage + ")" : null} center / cover;
            background-position: ${bkgImagePositionMobile ? null : "30% 0%"};
            padding: ${rem(spacing(6))};
        }

        ${mediaMin(theme.breakpoint.lg)} {
            background-position: ${bkgImagePosition ? bkgImagePosition : "0% 100%"};
            padding: ${rem(spacing(6))} ${rem(spacing(12.5))};
        }

        ${mediaMin(theme.breakpoint.xl)} {
            background-position: ${bkgImagePosition ? bkgImagePosition : "50% 50%"};
            padding: ${rem(spacing(6))} ${rem(spacing(17.5))};
        }

        ${mediaMin(theme.breakpoint.xxl)} {
            padding: ${rem(spacing(6))} ${rem(spacing(22.5))};
        }

        .smile {
            color: ${shapeVariant ? theme.color.palette[shapeVariant] : theme.color.palette.primary};
            left: ${shapeLeft ? shapeLeft : "38%"};
            position: absolute;
            top: 35%;
            width: 88%;
            z-index: 1;

            path {
                stroke-width: 3px;
            }

            ${mediaMin(theme.breakpoint.lg)} {
                top: ${shapeTop ? shapeTop : "13%"};
            }
        }
    `,
);

export const OverlayMockUpMobile = styled(MockUpMobile)<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        display: none;

        ${mediaMin(theme.breakpoint.lg)} {
            bottom: -5%;
            display: block;
            height: 80%;
            margin: 0;
            position: absolute;
            right: 8%;
            width: auto;
            z-index: 4;
        }

        ${mediaMin(theme.breakpoint.xl)} {
            bottom: -10%;
            height: 100%;
        }

        ${mediaMin(theme.breakpoint.xxl)} {
            right: 10%;
        }
    `,
);

export const ShapeContainer = styled("div")<HTMLAttributes<HTMLImageElement>>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    backface-visibility: hidden;
    pointer-events: none;
`;

ShapeContainer.displayName = "ShapeContainer";
