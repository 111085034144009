import { routeToUrl } from "@app-routes";
import { useHandleAddress } from "@hooks/useHandleAddress";
import { SelectOption } from "@ui/AutoComplete/types";
import { FormSelect } from "@ui/FormSelect/FormSelect";
import { ISelectKeyValues } from "@utils/formHelper";
import { forwardRef, memo, useCallback, useState } from "react";
import Async from "react-select/async";
import { LandingAddressSelectProps } from "./types";

const LandingAddressSelectComponent = forwardRef<Async<SelectOption>, LandingAddressSelectProps>((props, ref) => {
    const { buttonProps = {}, label = "Kdo je okolo vás?", placeholder = "Zadejte vaši adresu...", source } = props;
    const [smartAddressId, setSmartAddressId] = useState<number>();

    const [handleAddress] = useHandleAddress();

    const handleOnInputChange = useCallback(
        (item: ISelectKeyValues): void => {
            setSmartAddressId(parseInt(item?.value, 10));
        },
        [setSmartAddressId],
    );

    return (
        <FormSelect
            buttonProps={{
                size: "huge",
                variant: "primary",
                width: 170,
                href: routeToUrl("landing-zone/around-me", { smartAddressId, source }),
                ...buttonProps,
            }}
            id="address"
            label={label}
            formElementProps={{
                name: "address",
                loadOptions: handleAddress,
                onChange: handleOnInputChange,
                placeholder,
                noOptionsMessage: () => "Zadejte vaši adresu...",
            }}
            ref={ref}
        />
    );
});

LandingAddressSelectComponent.displayName = "LandingAddressSelect";

export const LandingAddressSelect = memo(LandingAddressSelectComponent);
